<!-- =========================================================================================
  File Name: ECommerceShop.vue
  Description: eCommerce Shop Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="shop-page">

    <ComingSoon v-if="!showShop" />
    <store-homepage v-if="showShop"/>
  </div>
</template>

<script>
import {
  AisInstantSearchSsr,
  AisClearRefinements,
  AisConfigure,
  AisHierarchicalMenu,
  AisHits,
  AisNumericMenu,
  AisPagination,
  AisRangeInput,
  AisRatingMenu,
  AisRefinementList,
  AisSearchBox,
  AisSortBy,
  AisStats,
  createServerRootMixin,
} from "vue-instantsearch";

import algoliasearch from "algoliasearch/lite";
import { VTree, VSelectTree } from "vue-tree-halower";
import { mapActions, mapState } from "vuex";
import TreeItem from "../../components/order-center/TreeItem";
import ClinicSwap from "../../components/shop/clinic.vue";
import _ from "lodash";
import ComingSoon from "../../clinic/ClinicShopComingSoon.vue";
import LDClient from "launchdarkly-js-client-sdk";
import StoreHelper from "../../../mixins/Store.vue";
import ContactFresh from "../../components/ContactFresh"
import Navbar from "../../components/shop/Navbar.vue";
import SearchInput from "../../../views/components/shop/Search.vue";

const searchClient = algoliasearch(
  "latency",
  "6be0576ff61c053d5f9a3225e2a90f76s"
);
export default {
  name: "OrderCenter",
  mixins: [
    StoreHelper,
    createServerRootMixin({
      searchClient,
      indexName: "instant_search",
    }),
  ],
  inject: ['getRoleRouteName'],
  components: {
    TreeItem,
    ClinicSwap,
    ComingSoon,
    ItemGridView: () => import("@/views/components/order-center/ItemGridView.vue"),
    ItemListView: () => import("./ItemListView.vue"),
    StoreHomepage: () => import("@/views/superAdmin/store/homepage/homepage.vue"),
    AisClearRefinements,
    AisInstantSearchSsr,
    AisConfigure,
    AisHierarchicalMenu,
    AisHits,
    AisNumericMenu,
    AisPagination,
    AisRangeInput,
    AisRatingMenu,
    AisRefinementList,
    AisSearchBox,
    AisSortBy,
    AisStats,
    VTree,
    VSelectTree,
    ContactFresh,
    Navbar,
    SearchInput
  },
  data() {

    return {
      contactEmail: this.contactFresh('email'),
      contactNumber: this.contactFresh('number'),
      hasOrganization: false,
      showShop: false,
      showFilter: false,
      popupActive: false,
      filterParams: {
        categoriesOptions: ["Homepage"],
        vendorOptions: [],
        sortBy: "order_asc",
        search: "",
        page: 1,
        limit: 12,
      },
      treeCategories: [],
      page: 1,
      suppliers: [],
      productParams: {
        page: 1,
        limit: 12,
      },
      totalDocs: 0,
      searchClient: algoliasearch(
        "latency",
        "6be0576ff61c053d5f9a3225e2a90f76"
      ),
      sortItems: [
        {
          value: "order_asc",
          label: "Sort by featured",
          id: 1,
        },
        {
          value: "price_asc",
          label: "Lowest Price",
          id: 2,
        },
        {
          value: "price_desc",
          label: "Highest Price",
          id: 3,
        },
      ],
      selectedCategoryDescription: "",
      // Filter Sidebar
      isFilterSidebarActive: true,
      clickNotClose: true,
      currentItemView: "item-list-view",
      filteredItems: [],
      AllLimitations: [],
      limitationsList: [],
      productList: [],
      itemsPerPage: 12,
      currentPage: 1,
      alertBanner: [],
      learnMorePopupActive: false,
      selectedBanner: {},
      isMobileView: null,
    };
  },
  computed: {
    ...mapState("eCommerce", ["searchText"]),
    toValue() {
      return (value, range) => [
        value.min !== null ? value.min : range.min,
        value.max !== null ? value.max : range.max,
      ];
    },
    toggleScreenSize: function () {
      if (this.isMobileView) {
          return !this.learnMorePopupActive;
      }
      return true
    },
    totalPage: function () {
      return 0;
      // return this.totalDocs / 10 >
      //   parseInt(this.totalDocs / this.productParams.limit)
      //   ? parseInt(this.totalDocs / this.productParams.limit) + 1
      //   : parseInt(this.totalDocs / this.productParams.limit);
    },

    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return _.slice(this.filteredItems, startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

    totalItemQuantityIncart() {
      return (itemId) =>
        this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },

    // GRID VIEW
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
    validateForm() {
      return !this.errors.any();
    },
    showCategoryDescription() {
      return this.selectedCategoryDescription && this.filterParams.categoriesOptions.length !== 0
    },
    getCartInfo() {
      return (itemId) => this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
  },
  methods: {
    ...mapActions("ecommerce", ["fetchFilterData", "getCartItemsFromApi", "productFilterListing"]),
    ...mapActions("appUser", ["fetchAppUserIndex"]),
    ...mapActions("storeProducts", ["fetchStoreLimitation"]),
    ...mapActions("storeAlertNotification", ["fetchStoreAlertsBanner"]),
    myEventHandler() {
      this.isMobileView = window.innerWidth < 768;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
    },
    onLaunchDarklyReady() {
      // if (this.ldClient.allFlags().shop) {
      //   this.showShop = this.ldClient.allFlags().shop;
      // }
    },
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    contactFresh(contact) {
      if (process.env.VUE_APP_REGION !== 'AU') {
        if (contact === 'email') return this.contactEmail = "hello@freshclinics.com"
        if (contact === 'number') return this.contactNumber = "+1 888-925-1462"
      }

      if (contact === 'email') return this.contactEmail = "info@freshclinics.com.au"
      if (contact === 'number') return this.contactNumber = "1300 375 646"

    },
    show(category) {
      this.selectedCategoryDescription = category.categoryDescription
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        let parentCategory = treeCategories[i];
        if (parentCategory._id == category._id) {

          /** toggle */
          const showStatus = !!treeCategories[i].show
          treeCategories[i].show = !showStatus;

          if (showStatus) {

            treeCategories[i].ancestors = treeCategories[i].ancestors.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

            for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
              if (treeCategories[i].ancestors[j].ancestors.length) {
                treeCategories[i].ancestors[j].hasIcon = true;
              } else {
                treeCategories[i].ancestors[j].hasIcon = false;
              }
            }

          }

        } else {
          treeCategories[i].show = false;
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(category);
    },
    selectChild(category, selectedChildCategory) {
      this.selectedCategoryDescription = selectedChildCategory.productDescription
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        let parentCategory = treeCategories[i];
        if (parentCategory._id == category._id) {
          // This is when child is selected
          for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
            let childCategory = treeCategories[i].ancestors[j];
            if (childCategory._id == selectedChildCategory._id) {
              treeCategories[i].ancestors[j].show = true;
              if (treeCategories[i].ancestors[j].ancestors) {
                for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
                  treeCategories[i].ancestors[j].ancestors[k].show = false;
                }
              }
            } else {
              treeCategories[i].ancestors[j].show = false;
              if (treeCategories[i].ancestors[j].ancestors) {
                for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
                  treeCategories[i].ancestors[j].ancestors[k].show = false;
                }
              }
            }
          }
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(selectedChildCategory);
    },
    selectSubChild(category, selectedChildCategory) {
      let treeCategories = _.cloneDeep(this.treeCategories);
      for (let i = 0; i < treeCategories.length; ++i) {
        for (let j = 0; j < treeCategories[i].ancestors.length; ++j) {
          let parentCategory = treeCategories[i].ancestors[j];
          if (parentCategory._id == category._id) {
            for (let k = 0; k < treeCategories[i].ancestors[j].ancestors.length; ++k) {
              let childCategory = treeCategories[i].ancestors[j].ancestors[k];
              if (childCategory._id == selectedChildCategory._id) {
                treeCategories[i].ancestors[j].ancestors[k].show = true;
              } else {
                treeCategories[i].ancestors[j].ancestors[k].show = false;
              }
            }
          }
        }
      }
      this.treeCategories = treeCategories;
      this.selectCategory(selectedChildCategory);
    },
    selectCategory(value) {
      this.filterParams.categoriesOptions = [value._id];
      if (value._id !== undefined) {
        this.filterParams.search = "";
      }
      if (value.show) {
        this.filterParams.categoriesOptions = []
      }
      this.getProductsStore();
    },
    handleChangePage(page) {
      this.filterParams.page = page;
      this.getProductsStore();
    },
    quantityChange(data) {
      if (this.filteredItems[data.index].type !== 'basic' ) {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: this.filteredItems[data.index]._id,
            },
          })
          .catch(() => { });
        return false;
      }
      this.filteredItems[data.index].selectedQuantity = data.quantity;
    },
    async getTreeCategories() {
      this.fetchFilterData().then((res) => {
        this.treeCategories = _.sortBy(res.data.data.treeCategories, [
          function (o) {
            return o.name;
          },
        ]);
        this.suppliers = res.data.data.suppliers;
        const buffer = this.treeCategories;
        this.treeCategories = buffer.filter(({ name }, id) => {
          return !buffer.some(({ ancestors }) =>
            ancestors.some(({ name: names }) => names === name)
          );
        });
      }).catch(err => {
        this.$vs.loading.close();
      });
    },
    async allOptionsData(options) {
      this.filterParams.categoriesOptions = options;
      this.getProductsStore();
    },
    // queryProduct: _.debounce(function(type,options = null){
    //     if (type === "vendor") {
    //       this.filterParams.vendorOptions = options;
    //     }
    //     if (type === "search"){
    //       this.show(this.filterParams.categoriesOptions)
    //       this.filterParams.categoriesOptions = [];
    //       this.filterParams.vendorOptions = [];
    //     }
    //     this.getProductsStore();
    //   },800),
    async getStoreLimitiation() {
      const { data } = await this.fetchStoreLimitation();
      this.AllLimitations = data.data.docs;
    },
    async getProductsStore() {
      try {
        this.$vs.loading({
          container: this.$refs.loadableDiv
        })
        const res = await this.productFilterListing(this.filterParams)

        this.filteredItems = [];
        this.productList = res.data.data.products;
        this.filteredItems = res.data.data.products;
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()

        this.goToPage(1)

      } catch (err) {
        // capture if no org
        if (err && err.status === 400) {
          this.$vs.notify({
            title: "Error",
            text: "No Organisation assigned. Can't checkout/order.",
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        }
        console.log(err);
        this.$vs.loading.close(this.$refs.loadableDiv)
        this.$vs.loading.close()
      }
    },
    async getBanners() {
      try {
        const res = await this.fetchStoreAlertsBanner({type: "homepage"})
        const tempData = [...res.data.data]
        tempData.map(alert => {
          if(alert.alert_type === "info") alert.alert_type = "primary"
          if(alert.alert_type === "error") alert.alert_type = "danger"
          return alert
        })
        this.alertBanner = tempData
      } catch (error) {
        console.log(error)
      }
    },
    handleSelectBanner(details) {
      this.selectedBanner = {...details}
      this.learnMorePopupActive = true;
    },
    // GRID VIEW - ACTIONS
    toggleFilterSidebar() {
      if (this.clickNotClose) return;
      this.isFilterSidebarActive = !this.isFilterSidebarActive;
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },

    additemInCart(data, type) {
      let minQty = 1;
      if(data.inventory.requiredQuantity > 1) {
        minQty = data.inventory.requiredQuantity
      }
      let item = {data: data, type: type, minQty: minQty}

      this.$vs.loading();

      item.data.shipping.shippingFee = item.data.supplier.deliveryDetails ? item.data.supplier.deliveryDetails.deliveryFee : 0;

      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if(response.status === 200 && response.data.message === 'success') {
          this.getCartItemsFromApi()
          this.$vs.notify({
            color: 'success',
            title: 'Product added',
            text: 'Product was successfully added to your cart.'
          })
        } else {
          this.$vs.notify({
            color: 'danger',
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`
          })
        }

        this.$vs.loading.close();
      });
    },
    async cartButtonClicked(item) {
      if (item.type !== 'basic' || item.tcc || item.type === 'bundle' || item.type === 'lead' || item.type === 'script') {
        this.$router
          .push({
            name: this.getRoleRouteName("ProductDetail"),
            params: {
              id: item._id,
            },
          })
          .catch(() => { });
        return false;
      }

      let isValid = await this.$validator.validateAll("basic");
      if (!isValid) {
        this.$vs.notify({
          title: "Error",
          text: "Validation Failed",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
        return false;
      }
      if (item.canAdd === false) {
        this.$vs.notify({
          title: "Failed",
          text:
            "Selected quantity must not be less than " +
            item.inventory.requiredQuantity,
          color: "danger",
        });
        return false;
      }
      const cartQuantity = this.totalItemQuantityIncart(item._id);
      if (item.selectedQuantity === 0) {
        this.$vs.notify({
          title: "Failed",
          text: "Quantity cannot be zero",
          color: "danger",
        });
        return false;
      }
      if (item.isAvailable !== true) {
        this.$vs.notify({
          title: "Failed",
          text: "Product is not available right now.",
          color: "danger",
        });
        return false;
      }

      const totalCartAndSelectedQty = (cartQuantity + item.selectedQuantity)
      if (item.inventory.maxQuantity !== null && item.inventory.maxQuantity !== 0) {
        if (item.selectedQuantity > item.inventory.maxQuantity || totalCartAndSelectedQty > item.inventory.maxQuantity) {
          this.$vs.notify({
            title: "Failed",
            text: "You've exceeded maximum order amount.",
            color: "danger",
          });
          return false;
        }
      }
      /* if(item.selectedQuantity < item.quantity){
           this.$vs.notify({
             title: "Failed",
             text: 'Selected quantity is greater than available quantity',
             color: "danger",
           });
           return false;
         }*/
      // if((cartQuantity + item.selectedQuantity) > item.quantity){
      //   this.$vs.notify({
      //     title: "Failed",
      //     text: 'Your cart exceeds the quantity of this product.',
      //     color: "danger",
      //   });
      //   return false;
      // }

      this.additemInCart(item, 'increment');
      // console.log(this.isInCart(item._id));
      // this.isInCart(item._id) ? this.$router.push('/apps/eCommerce/checkout').catch(() => {}) : this.additemInCart(item)
    },
    // async hasPublishedTemplate() {
    //   await this.getHomepagetemplates({ status: "published" })
    //     .then((result) => {
    //       this.hasPublishedStoreTemplate = result.data.data.length > 0;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }
  },
  beforeMount: async function () {
    const { email } = this.$store.state.AppActiveUser;
    const userIndex = await this.fetchAppUserIndex();
    this.getStoreLimitiation()
    const key = userIndex.data.data.count;
    const user = {
      email,
      key,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);

    this.hasOrganization = localStorage.getItem(
      `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
    );
  },
  created() {
    // this.hasPublishedTemplate();
    // this.getTreeCategories();
    // this.getProductsStore();
    const storeState = this.$store.state
    let showShop = this.$store.state.showStore;
    if (storeState.AppActiveUser && storeState.AppActiveUser.userRole === 'superAdmin') showShop = true
    this.showShop = showShop
  },
  mounted() {
    // window.addEventListener("resize", this.myEventHandler);
    // this.myEventHandler();
  },
  destroyed() {
    // window.removeEventListener("resize", this.myEventHandler);
  },
  head() {
    return {
      link: [
        {
          rel: "stylesheet",
          href: "https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/algolia-min.css",
        },
      ],
    };
  },
  watch: {
    // "$store.state.clinicId": function (val) {
    //   this.getProductsStore();
    //   this.getTreeCategories();
    // },
    "$store.state.showStore": function (val) {
      this.showShop = val;
    },
    searchText(value) {

      this.show(this.filterParams.categoriesOptions)
      this.filterParams.categoriesOptions = [];
      this.filterParams.vendorOptions = [];
      this.filterParams.search = value;
      this.getProductsStore()
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/extraComponents/tree.scss";
.custom-primary-bg {
  background-color: #84CDB8;
}
.content-wrapper{
  background-color: white;
}

.fade2-leave-active, .fade2-enter-active {
  @apply duration-150;
  @apply transition;
}

.fade2-enter, .fade2-leave-to {
  @apply opacity-0;
}

button.btn-async {
  background: rgba(var(--vs-warning), 0.15);
}

button.btn-delete {
  background: rgba(var(--vs-danger), 0.15);
}

div.bg-gray {
  background-color: #a0a0a0 !important;
}

#algolia-instant-search-demo {
  .algolia-header {
    .algolia-filters-label {
      width: calc(260px + 2.4rem);
    }
  }

  #algolia-content-container {
    .vs-sidebar {
      position: relative;
      float: left;
    }
  }

  .algolia-search-input-right-aligned-icon {
    padding: 1rem 1.5rem;
  }

  .algolia-price-slider {
    min-width: unset;
  }

  .item-view-primary-action-btn {
    color: #2c2c2c !important;
    background-color: #f6f6f6;
    min-width: 50%;
  }

  .item-view-secondary-action-btn {
    min-width: 50%;
  }
}

.theme-dark {
  #algolia-instant-search-demo {
    #algolia-content-container {
      .vs-sidebar {
        background-color: #10163a;
      }
    }
  }
}

@media (min-width: 992px) {
  .vs-sidebar-rounded {
    .vs-sidebar {
      border-radius: 0.5rem;
    }

    .vs-sidebar--items {
      border-radius: 0.5rem;
    }
  }

  .item-view-secondary-action-btn {
    // border-radius: 0 0 5px 0 !important;
  }
}

@media (max-width: 992px) {
  #algolia-content-container {
    .vs-sidebar {
      position: absolute !important;
      float: none !important;
    }
  }
}

.tree-list ul>li {
  margin-left: 20px;
  margin-bottom: 10px;
}

.tree-list li {
  margin-bottom: 10px;
}

.tree-list li ul {
  margin-top: 10px;
}

.add-to-cart-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

[dir] .con-select .vs-select--input {
  border: 0 !important;
  padding: 13px;
  font-size: 1.1rem;
}

.vs-select--options span {
  font-size: 1.1rem;
}

.items-wrapper  {
  > div {
    border-left: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;

    &:first-of-type,
    &:nth-of-type(2) {
      border-top: 1px solid #eaeaea;
    }

    &:nth-of-type(even) {
      border-left: none;
      border-right: 1px solid #eaeaea;
    }

    &:nth-of-type(odd) {
      border-right: 1px solid #eaeaea;
    }
  }
}

@media (min-width: 992px) {
  .items-wrapper > div{
    border: 1px solid #eaeaea !important;
    border-radius: .3rem;
  }

}
@media (max-width: 991px) {
  .router-view {
    padding: 2rem 0 !important;
  }
}

.learnMorePopup .vs-popup {
  width: 50%;
}


</style>
