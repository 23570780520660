<template>
    <p>
        Sorry! You need to setup a supplier account to purchase this product. Please contact <b>{{cNumber}}</b> or <a :href="'mailto:' + cEmail"> {{cEmail}}</a>
    </p>
</template>
<script>

export default {
  name: "ContactFresh",
  props: ["number", "email"],
  data() {
    return{
        cNumber: this.number ? this.number : '1300 375 646',
        cEmail: this.email ? this.email : 'info@freshclinics.com.au'
    }
  }
};
</script>