<template>
    <div class="relative">
      <div 
        class=" text-lg font-medium menuTitle py-1 px-2 rounded-lg cursor-pointer"
        :class="`${isMenuOpen ? 'bg-primary2 text-white' : 'text-primary2'}`" 
        @click="handleMenuClick"
        
      >
        {{ title }}
      </div>
  
      <div v-show="isMenuOpen" class="absolute mt-2 py-2 px-4 z-40 menuItems menu-container overflow-x-auto" style="height: 85vh; left: -151px">
        <div class=" brand-dropdown-position p-5">
            <!-- <h1>Brands</h1> -->
            <div class=" my-5">
                <span class="text-primary2 font-medium">Popular Brands</span>
                <div class="grid grid-cols-2 lg:grid-cols-6 gap-2">
                    <div
                        v-for="i, index in brandData.featured_brands"
                        :key="index"
                        class="brand-card cursor-pointer hover:bg-primary3 w-full flex items-center justify-center"
                        @click="handleSubItemClick(i.slug)"
                    >
                        <img
                            class="w-8/12 object-cover"
                            style="max-height: 70px"
                            :src="i.image"
                            alt="brand_image"
                        />
                    </div>
                </div>
                <div class=" my-5">
                    <span class="text-primary2 font-medium">Anti Wrinkle Brands</span>
                    <div class=" flex">
                        <div 
                          class=" text-primary2 hover:underline font-normal category-items pr-5"
                          @click="handleSubItemClick(item.slug)"
                          v-for="item, index in brandData.antiwrinkle_brands"
                          :key="index"
                        >
                            {{ item.name }}
                        </div>
                    </div>
                </div>
                <div class=" my-5">
                    <span class="text-primary2 font-medium">Filler Brands</span>
                    <div class=" flex flex-wrap">
                      <div 
                        class=" text-primary2 hover:underline font-normal category-items pr-5"
                        @click="handleSubItemClick(item.slug)"
                        v-for="item, index in brandData.filler_brands"
                        :key="index"
                      >
                          {{ item.name }}
                      </div>
                    </div>
                </div>
                <!-- <div class=" my-5">
                    <span class="text-primary2 font-medium">Brands A - Z</span>
                    <div
                      
                    >
                        <a
                            v-for="item, index in brandData.all_brands"
                            :key="index"
                            class=" text-primary2 hover:underline font-normal"
                            style="font-size: 14px; width: fit-content"
                        >
                            {{ item.name }}
                            <br>
                        </a>
                    </div>
                </div> -->
                <div class=" mr-5 flex-shrink-0 menu-categories-width" >
                  <div class="text-lg font-medium text-primary2" style="font-size: 18px">
                    Shop By Brands
                  </div>
                  <div
                    id="categories"
                    class="pr-4 menu-height flex flex-row flex-wrap whitespace-no-wrap"
                  >
                    <a
                      @click="handleSubItemClick(item.slug)"
                      v-for="item, index in brandData.all_brands"
                      :key="index"
                      class=" text-primary2 hover:underline font-normal category-items pr-5"
                      style="font-size: 14px"
                      >
                      <!-- :class="UnderlineBrand(item.slug)" -->
                      {{ item.name }}
                      <br>
                    </a>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      title: String,
      brandData: Object,
      isMenuOpen: Boolean
    },
    data() {
      return {
        activeItem: null,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
      handleMenuClick(){
        this.$emit('menuClick');
      },
      handleItemClick(item) {
        this.activeItem = this.activeItem === item ? null : item;
      },
      handleSubItemClick(subItem) {
        // this.toggleMenu();
        this.$emit('itemClick', subItem);
      },
    },
  };
  </script>
  
  <style scoped>
  
  .subItems{
    left: 248px;
    border: 1px solid #E5E7EB !important;
    background-color: white;
  }
  
  .menuItems {
    min-width: 264px;
    border: 1px solid #E5E7EB !important;
    background-color: white;
  }

  /* .menu-categories-width {
    min-width: 190px;
    width: var(--megamenu-categories-width) !important;
    } */

    .menu-height{
      min-width: 190px;
      writing-mode: vertical-lr;
      max-height: auto;
      height: 300px;
      /* @media (min-width: 768px) {
        height: 570px;
      }
      @media (min-width: 900px) {
        height: 550px;
      }
      @media (min-width: 1400px) {
        height: 510px;
      } */
    }
  
    .menuTitle:hover {
      background-color: #F3FAF8;
    }
  .menu-container {
    @media (min-width: 1200px) {
      width: calc(100vw - 297px - var(--scrollbar-width));
    }
    @media (max-width: 1200px) {
      width: calc(100vw - 36px - var(--scrollbar-width));
    }
  }

  .brand-card {
    width: 150px;
    height: 150px;
    flex-shrink: 0;
    border: 1px solid #E5E7EB !important;
  }

</style>
  