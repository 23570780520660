<template>
  <div class="wrapper flex justify-center">
    <div class="container">
      <div class="text-center">
        <h1>Fresh Clinics Shop</h1>
        <h1>Coming to your dashboard soon!</h1>
      </div>
      <div class="text-center my-12">
        <span>Already have access to our shop?</span>
        <br />
        <span>Click the button to login.</span>
      </div>
      <div class="text-center">
        <vs-button @click="goToOrderSite()">Go to order site</vs-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ClinicShopComingSoon",
  methods: {
    goToOrderSite() {
      window.open("https://orders.freshclinics.com.au/wp-login.php")
      // this.$router.push("/clinic/orders");
    },
  },
};
</script>

<style lang="scss">
.container {
  margin-top: 136px;
}
</style>