<template>
    <div class="relative">
        <div 
            class="hidden md:block text-lg font-medium menu-title py-1 px-2 rounded-lg cursor-pointer" 
            :class="isMenuOpen ? 'bg-primary2 text-white' : 'text-primary2'"
            @click="toggleMenu"
        >
            {{ title }}
        </div>
        <div class="md:hidden">
            <div 
                class="mr-3 font-medium menuTitle rounded-lg cursor-pointer text-lg leading-normal text-black px-2"
                :class="isMenuOpen ? 'bg-primary2 text-white' : 'text-primary2'"
                @click="toggleMenu"
            
            >
                {{ title }}
            </div>
        </div>
      
        <div v-show="isMenuOpen" class="absolute mt-2 py-2 px-4 z-40 menu-items min-width">
            <div class="flex flex-col">
                <div 
                    class="cursor-pointer mb-2 text-primary2 hover:underline"
                    @click="onPromotionClick"
                >
                    Promotions
                </div>
                <div 
                    class="cursor-pointer mb-2 text-primary2 hover:underline"
                    @click="onFreshLifePromotionClick"
                >
                    Fresh Life Promos
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        isMenuOpen: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        toggleMenu() {
            this.$emit('toggle-menu');
        },
        onPromotionClick(){
            this.$emit('promotionClick');
        },
        onFreshLifePromotionClick(){
            this.$emit('freshLifePromotionClick');
        },
        navigateTo(url) {
            window.location.href = url;
        },
    },
};
</script>

<style scoped>
.menu-items {
    border: 1px solid #E5E7EB !important;
    background-color: white;
}

.menu-title:hover {
    background-color: #F3FAF8;
}

.min-width {
    min-width: 200px; 
}
</style>
  