<template>
  <div class="nav-wrapper md:w-full">

    <nav class="mx-auto flex flex-wrap relative mt-2">

      <ul class="flex flex-row justify-between items-center">
        <li>
          <Navigation />
        </li>
        <!-- <li class="mr-4 " v-for="item in popularCategory" :key="item.id">
          <a @click="onCategoryClick(item)"
            class="text-sm whitespace-no-wrap font-medium cursor-pointer text-primary2 hover:underline" style="text-underline-offset: 20px; text-decoration-thickness: 3px !important;" :class="UderlineCategory(item.slug)" >{{
              item.name }}</a>
        </li> -->
      </ul>

    </nav>
  </div>
</template>
  
<script>
import { mapActions } from "vuex";
import MegaMenu from './MegaMenu.vue';
import SideBarCategories from './SideBarCategories.vue'
import SearchInput from './Search.vue'
import ClinicSwap from "../../../views/components/shop/clinic.vue";
import Navigation from './Navigation.vue';

export default {
  components: {
    MegaMenu,
    SideBarCategories,
    SearchInput,
    ClinicSwap,
    Navigation
  },
  data() {
    return {
      menuOpen: false,
      popularCategory: [],
      showSidebar: false,
      ParentCategories: [],
    }
  },
  methods: {
    ...mapActions("category", [
      "getPopularCategory"
    ]),
    toggleMenu() {
      this.showSidebar = !this.showSidebar
    },
    onCategoryClick(data) {
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterCategoryPage"
      }
      else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenterCategoryPage"
        }
        else {
          routerName = "NurseOrderCenterCategoryPage"
        }
      }
      this.$router.push({
        name: routerName,
        params: {
          slug: data.slug
        },
        query: {
          page: 1
        }
      })
      // if (this.$route.params.slug) {
      //   this.$router.replace({
      //     params: {
      //       slug: data.slug
      //     }
      //   })
      // }
      // else {
      //   if (data.slug) {
      //     this.$router.push({
      //       name: routerName,
      //       params: {
      //         slug: data.slug
      //       }
      //     })
      //   }
      // }
    },
    UderlineCategory(slug) {
      return {
        'underline':this.$route.params.slug === slug
      }
    }
  },
  computed: {
    baseUrl: function () {
      const host = window.location.protocol + "//" + window.location.host
      const userPath = `${this.$route.path}`.split('/')[1]
      return `${host}/${userPath}`
    },
    SidebarCategories() {
      return _.unionBy(this.popularCategory, this.$store.state.category.parentMenuCategories, "name")
    }
  },
  created() {
    this.getPopularCategory().then(({ data }) => this.popularCategory = data.data);
  },
}
</script>

<style scoped lang="scss">
li::after {
  border: 1px;
  border: solid;
}

.nav-wrapper {
  margin-top: -8px;
  // width: 100%;
  display: flex;
  padding-top: 0px !important;

  @media (min-width: 768px) {
    border-bottom: solid 0.5px lightgray;
    padding-bottom: 13px;
    /* padding-bottom: 20px; */
    /* margin-bottom: 10px; */
  }

}

.vs-sidebar {
  z-index: 50000
}

.vs-sidebar--background {
  z-index: 50000
}

.vs-sidebar--header {
  background-color: #074230;
}

.vs-sidebar .vs-sidebar--items {
  overflow: auto;
}
</style>