<template>
  <div class="flex-shrink-0">
    <!-- <vs-button
      @click="toggleMenu"
      icon="expand_more"
      icon-after
      class="text-primary2 font-medium cursor-pointer flex items-center justify-center relative z-10 border-none bg-green-lightest rounded-full px-4 py-2 categories-button"
    >  Categories
    </vs-button> -->

    <vs-sidebar
      :click-not-close="true"
      class="md:hidden products-sidebar relative spacer"
      parent="body"
      default-index="-1"
      v-model="showSidebar"
      style="z-index: 51000;"
    >
      <div class="flex items-center px-4" slot="header">
        <vs-icon
          class="cursor-pointer mr-3"
          @click="toggleMenu"
          :style="{ height: '24px', width: '24px' }"
          size="small"
          color="white"
          icon="close"
        />
        <p class="font-normal text-white" style="font-size: 18px">All Categories</p>
      </div>

      <div class="mx-6 text-primary2 text-lg pt-4">
        <div class="font-medium" style="font-size: 18px">Featured Categories</div>
        <div class="category-col">
          <div
            v-for="category in popularCategory.filter(item => item.slug)"
            :key="category._id"
            class="mt-1"
          >
            <a
              @click="onCategoryClick(category)"
              class="text-primary2 font-medium hover:underline cursor-pointer whitespace-wrap"
              style="font-size: 14px"
              :class="UnderlineCategory(category.slug)"
              >{{ category.name }}</a
            >
          </div>
        </div>

        <div class="font-medium mt-5" style="font-size: 18px">Featured Brands</div>
        <div class="category-col">
          <div
            v-for="brand in popularBrands.filter(item => item.slug)"
            :key="brand._id"
            class="mt-1"
          >
            <a
              @click="onBrandClick(brand)"
              class="text-primary2 font-medium hover:underline cursor-pointer whitespace-wrap"
              style="font-size: 14px"
              :class="UnderlineBrand(brand.slug)"
              >{{ brand.brandName }}</a
            >
          </div>
        </div>

          <div class="font-medium text-xl mt-5" style="font-size: 18px">Categories</div>
        <div class="category-col">
          <div
            v-for="category in ParentCategories.filter(item => item.slug)"
            :key="category._id"
            class="mt-1"
          >
            <a
              @click="onCategoryClick(category)"
              class="text-primary2 cursor-pointer category parent hover:underline whitespace-wrap"
              style="font-size: 14px"
              :class="UnderlineCategory(category.slug)"
              >{{ category.name }}</a
            >
          </div>
        </div>

          <div class="font-medium text-xl mt-5" style="font-size: 18px">Shop by Brands</div>
        <div class="category-col">
          <div
            v-for="brand in Brands.filter(item => item.slug)"
            :key="brand._id"
            
            class="mt-1 "
          >
            <a
              @click="onBrandClick(brand)"
              :class="UnderlineBrand(brand.slug)"
              class="text-primary2 cursor-pointer category parent hover:underline whitespace-wrap"
              >{{ brand.name }}</a
            >
          </div>
        </div>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { ChevronDownIcon } from "vue-feather-icons";

export default {
  components: {
    ChevronDownIcon
  },
  data() {
    return {
      popularCategory: [],
      popularBrands: [],
      showSidebar: false,
      brandList: [],
      windowWidth: 0,
    };
  },
  methods: {
    ...mapActions("category", ["getPopularCategory"]),
    ...mapActions("brand", ["getBrandAll","getPopularBrands"]),
    toggleMenu() {
      this.showSidebar = !this.showSidebar;
      if(this.showSidebar){
        document.body.style.overflow = 'hidden';
      }else{
        document.body.style.overflow = 'auto';
      }
    },
    onBrandClick(data){
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterBrandPage";
      } else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenterBrandPage";
        } else {
          routerName = "NurseOrderCenterBrandPage";
        }
      }
      this.$router.push({
        name: routerName,
        params: {
          slug: data.slug
        },
        query: {
          page: 1
        }
      });
      
      this.toggleMenu()
    },
    onCategoryClick(data) {
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterCategoryPage";
      } else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenterCategoryPage";
        } else {
          routerName = "NurseOrderCenterCategoryPage";
        }
      }
      if (this.$route.params.slug) {
        this.$router.replace({
          params: {
            slug: data.slug
          },
        query: {
          page: 1
        }
        });
      } else {
        if (data.slug) {
          this.$router.push({
            name: routerName,
            params: {
              slug: data.slug
            },
            query: {
              page: 1
            }
          });
        }
      }
      this.toggleMenu()
    },
    UnderlineCategory(slug) {
      if(slug !== undefined){
        const path = this.$route.path
        const isCategoryPage = path.split("/")[3] === "category"
        return {
        'underline':this.$route.params.slug === slug && isCategoryPage
      }
      }
    },
    UnderlineBrand(slug) {
      if(slug !== undefined){
        const path = this.$route.path
        const isBrandPage = path.split("/")[3] === "brands"
        return {
        'underline':this.$route.params.slug === slug && isBrandPage
      }
      }
    },
    UderlineHomepage() {
      return this.$route.params.slug === undefined ? 'underline' : ''
  
    },
    handleResize(){
      this.windowWidth = window.innerWidth
      if(this.windowWidth >= 768){
        document.body.style.overflow = 'auto';
      }
      if(this.windowWidth <= 768 && this.showSidebar == true){
        document.body.style.overflow = 'hidden';
      }
    }
  },
  computed: {
    baseUrl: function() {
      const host = window.location.protocol + "//" + window.location.host;
      const userPath = `${this.$route.path}`.split("/")[1];
      return `${host}/${userPath}`;
    },
    ParentCategories() {
      return _.differenceBy(
        this.$store.state.category.parentMenuCategories,
        this.popularCategory,
        "name"
      );
    },
    Brands() {
    return _.differenceBy(
        this.brandList,
        this.popularBrands,
        "_id"
      );
    },
  },
  created() {
    this.getPopularCategory().then(
      ({ data }) => (this.popularCategory = data.data)
    );
    this.getBrandAll().then(({ data }) => (this.brandList = _.sortBy(data,"name")));

    this.getPopularBrands().then(({ data }) => (this.popularBrands = data.data));

    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style scope lang="scss">
.vs-sidebar {
  z-index: 50000;
}

.categories-button {
  border-radius: 5rem !important;
}

.vs-sidebar--background {
  z-index: 50000;
}

.vs-sidebar--header {
  background-color: #074230;
  width: 100vw;
}

.products-sidebar .vs-sidebar--items {
  overflow: auto;
  width: 100vw;
  padding: 10px 0;
}
.category-col {
  column-count: 2;
  margin-top: .5rem;
}
</style>
