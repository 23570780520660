<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      limitationsList: [],
      AllLimitations: [],
      disableNext: false,
    };
  },
  async created() {
    await this.getStoreLimitiation();
    await this.checkLimitations(this.$store.state.eCommerce.cartItems.slice().reverse());
  },
  computed: {
    cartItems() {
      return this.$store.state.eCommerce.cartItems.slice().reverse();
    },
  },
  methods: {
    ...mapActions("storeProducts", ["fetchStoreLimitation"]),
    ...mapActions("ecommerce", ["getCartItemsFromApi"]),
    shouldDisableNext(condition, limitation) {
      if (condition && condition === "and") {
        return (limitation.isAboveMax || limitation.isBelowMin) && limitation.isBellowAmount;
      }
      return limitation.isAboveMax || limitation.isBelowMin || limitation.isBellowAmount;
    },
    checkLimitations(cartItems) {
      this.disableNext = false;
      const currentClincid = sessionStorage.getItem("doctorClinicSelectedClinic");
      const limitations = _.pullAll(_.cloneDeep(this.AllLimitations).map(limitation => {
        const condition = limitation.condition ? limitation.condition : null;
        const productIds = limitation.products.map(item => (item.productId));
        const clinicsIds = limitation.clinics.map(item => (item.clinicId));
        const produuctsInCurrentLimitation = _.pullAll(cartItems.map(product => {
          if (_.indexOf(productIds, product.productStoreId) >= 0) {
            return product
          }
          return undefined
        }), [undefined]);
          const productFromSameSupplierPrice = _.sum(cartItems.map(product=>{
            if(produuctsInCurrentLimitation.length > 0 && produuctsInCurrentLimitation[0].inventory.managedBy === product.inventory.managedBy ){
              return (product.price / 100) * product.quantity;
            }
            return 0
          }))
        const isForcurrentClinic = clinicsIds.length > 0 ? _.indexOf(clinicsIds, currentClincid) >= 0 : true
        if (isForcurrentClinic && produuctsInCurrentLimitation.length > 0) {
          const productQuantity = _.sum(produuctsInCurrentLimitation.map(product => (product.quantity)))
          limitation.isAboveMax = (!limitation.max_qty || limitation.max_qty === 0) ? false : productQuantity > limitation.max_qty;
          limitation.isBelowMin = productQuantity < limitation.min_qty;
          limitation.isBellowAmount = productFromSameSupplierPrice < limitation.min_purchase_amount

          // Apply condition from store limitation
          if (this.shouldDisableNext(condition, limitation)) {
            this.disableNext = true;

            return limitation
          }
          return undefined
        }
        return undefined
      }), [undefined]);
      this.limitationsList = limitations
      ;
    },
    async getStoreLimitiation() {
      const { data } = await this.fetchStoreLimitation();
      this.AllLimitations = data.data.docs;
    },
    getCartItems() {
      this.getCartItemsFromApi();
    },
  },
  watch: {
    "$store.state.eCommerce.cartItems": function (val) {
      this.checkLimitations(val);
    },
    "$store.state.clinicId": function () {
      this.getCartItems();
    },
  }
};
</script>
