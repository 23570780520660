<template>
  <div class="relative mega-menu flex ">
    <div class="flex items-center justify-center gap-4">
      <a class="m-0 p-0 items-center flex ml-5 cursor-pointer" @click="onHomeClick">
        <feather-icon icon="HomeIcon" svgClasses="h-6 w-6" class="ml-1 mb-2 text-primary2" />
      </a>

      <div
        @click="showMenu = !showMenu"
        class="font-medium uppercase cursor-pointer flex items-center"
      >
        <a
          class="text-sm font-medium cursor-pointer whitespace-no-wrap text-primary2"
          >All Categories</a
        >
        <chevron-down-icon v-if="!showMenu" class="h-5 w-5 text-primary2" />
        <chevron-up-icon v-if="showMenu" class="h-5 w-5 text-primary2"/>
      </div>
    </div>

    <transition name="mega-menu-fade">
      <div
        v-if="showMenu"
        class="absolute flex z-10 bg-white shadow-lg p-10 pt-5 rounded-sm mt-12 menu-container overflow-x-auto"
        @mouseover="showMenu = true"
        @mouseleave="showMenu = false"
      >
        <div class="mr-5 flex-shrink-0 menu-featured-category-width" > 
          <div class="font-medium mb-5 text-primary2 whitespace-no-wrap" style="font-size: 18px">
            Featured Categories
          </div>
          <div id="featured-categories" class="pr-4 menu-height flex flex-row flex-wrap" >
            <a
              @click="onCategoryClick(item)"
              v-for="item in popularCategory.filter(item => item.slug)"
              :key="item._id"
              class=" text-primary2 hover:underline font-medium category-items pr-5 whitespace-no-wrap "
              style="font-size: 14px"
              :class="UnderlineCategory(item.slug)"
            >
              {{ item.name }}
            <br>
            </a>
          </div>
        </div>

        <div class="mr-5 flex-shrink-0 menu-featured-brands-width" > 
          <div class="font-medium mb-5 text-primary2" style="font-size: 18px">
            Featured Brands
          </div>
          <div id="featured-brands" class="border-0 border-r border-gray-400 border-solid pr-4 menu-height flex flex-row flex-wrap" >
            <a
              @click="onBrandClick(item)"
              v-for="item in popularBrands.filter(item => item.slug)"
              :key="item._id"
              class=" text-primary2 hover:underline font-medium category-items pr-5 whitespace-no-wrap "
              style="font-size: 14px"
              :class="UnderlineBrand(item.slug)"
            >
              {{ item.brandName }}
            <br>
            </a>
          </div>
        </div>

        <div class=" mr-5 flex-shrink-0 menu-categories-width" >
          <div class="text-lg font-medium mb-5 text-primary2" style="font-size: 18px">
            Categories
          </div>
          <div
            id="categories"
            class="border-0 border-r border-gray-400 border-solid pr-4 menu-height flex flex-row flex-wrap whitespace-no-wrap"
          >
            <a
              @click="onCategoryClick(item)"
              v-for="item in Categories.filter(item => item.slug)"
              :key="item._id"
              class=" text-primary2 hover:underline font-normal category-items pr-5"
              style="font-size: 14px"
              :class="UnderlineCategory(item.slug)"
            >
              {{ item.name }}
              <br>
            </a>
          </div>
        </div>

        <div class=" mr-5 flex-shrink-0 menu-categories-width" >
          <div class="text-lg font-medium mb-5 text-primary2" style="font-size: 18px">
            Shop By Brands
          </div>
          <div
            id="categories"
            class="pr-4 menu-height flex flex-row flex-wrap whitespace-no-wrap"
          >
            <a
              @click="onBrandClick(item)"
              v-for="item in Brands.filter(item => item.slug)"
              :key="item._id"
              class=" text-primary2 hover:underline font-normal category-items pr-5"
              style="font-size: 14px"
              :class="UnderlineBrand(item.slug)"
            >
              {{ item.name }}
              <br>
            </a>
          </div>
        </div>

        <!-- <div class=" mr-5  ">
          <div class="text-lg font-medium mb-5 text-primary2">
            Shop By Brands
          </div>
          <div class=" h-full max-h-xxs column-categories">
            <a
              @click="onCategoryClick(item)"
              v-for="item in Categories"
              :key="item._id"
              class="text-sm hover:underline text-primary2  font-normal inline-block category-items pr-5 "
            >
              {{ item.name }}
            </a>
          </div>
        </div> -->
      </div>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";

export default {
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
  },
  data() {
    return {
      showMenu: false,
      showul: false,
      popularCategory: [],
      categories: [],
      brandList: [],
      popularBrands: [],
    };
  },
  computed: {
    ...mapState("category", ["parentMenuCategories"]),
    // baseUrl: function() {
    //   const host = window.location.protocol + "//" + window.location.host;
    //   const userPath = `${this.$route.path}`.split("/")[1];
    //   return `${host}/${userPath}`;
    // },
    Categories() {
      return _.differenceBy(
        this.parentMenuCategories,
        this.popularCategory,
        "name"
      );
    },
    Brands() {
      return _.differenceBy(
        this.brandList,
        this.popularBrands,
        "_id"
      );
    },
  },
  methods: {
    ...mapActions("ecommerce", ["fetchFilterData"]),
    ...mapActions("category", ["getPopularCategory"]),
    ...mapActions("brand", ["getBrandList", "getBrandAll","getPopularBrands"]),
    async getParentCategories() {
      this.fetchFilterData()
        .then(res => {
          // initial categories
          let categories = _.sortBy(res.data.data.treeCategories, "name");
          // get the suppliers
          this.suppliers = res.data.data.suppliers;

          const buffer = categories;

          // filter categories
          categories = buffer.filter(({ name }, id) => {
            // Hide hompage category
            if (["Homepage"].includes(name)) {
              return false;
            }
            return !buffer.some(({ ancestors }) =>
               ancestors.some(({ name: names, deleted }) => names === name && !deleted)
            );
          });

          // dispatch to store
          this.$store.dispatch(
            "category/updateMenuParentCategories",
            categories
          );
        })
        .catch(err => {
          this.$vs.loading.close();
        });
    },
    onHomeClick() {
      const owner = this.$route.path.split('/')[1]
      this.$router.push(`/${owner}/shop`)
    },
    onBrandClick(data){
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterBrandPage";
      } else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenterBrandPage";
        } else {
          routerName = "NurseOrderCenterBrandPage";
        }
      }
      this.$router.push({
        name: routerName,
        params: {
          slug: data.slug
        },
        query: {
          page: 1
        }
      });
    },
    onCategoryClick(data) {
      const userType = JSON.parse(
        localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
      ).userType;
      const role = localStorage.getItem(
        `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
      );

      let routerName = "";
      if (role === "Org Owner") {
        routerName = "OrgOwnerOrderCenterCategoryPage";
      } else {
        if (userType === "doctor") {
          routerName = "DoctorOrderCenterCategoryPage";
        } else {
          routerName = "NurseOrderCenterCategoryPage";
        }
      }
      this.$router.push({
        name: routerName,
        params: {
          slug: data.slug
        },
        query: {
          page: 1
        }
      });
    },
    UnderlineCategory(slug) {
      if(slug !== undefined){
        const path = this.$route.path
        const isCategoryPage = path.split("/")[3] === "category"
        return {
        'underline':this.$route.params.slug === slug && isCategoryPage
      }
      }
    },
    UnderlineBrand(slug) {
      if(slug !== undefined){
        const path = this.$route.path
        const isBrandPage = path.split("/")[3] === "brands"
        return {
        'underline':this.$route.params.slug === slug && isBrandPage
      }
      }
    },
    getFeaturedWidth() {
      const featuredCategories = document.getElementById("featured-categories");
      const featuredBrands = document.getElementById("featured-brands");

      if (featuredCategories !== null) {
      const featuredWidth =  featuredCategories.offsetWidth
        document.documentElement.style.setProperty(
        '--megamenu-featured-category-width',
        featuredWidth + "px"
      ); 
      }

      if (featuredBrands !== null) {
      const featuredWidth =  featuredBrands.offsetWidth
        document.documentElement.style.setProperty(
        '--megamenu-featured-brands-width',
        featuredWidth + "px"
      ); 
      }
      
    },
    getCategoriesWidth() {
      const categories = document.getElementById("categories");
      if (categories !== null) {
      document.documentElement.style.setProperty(
        '--megamenu-categories-width',
        categories.offsetWidth + "px"
      ); 
      }

    }
  },
  created() {
    if (isEmpty(this.parentMenuCategories)) {
      this.getParentCategories();
    }
    this.getPopularCategory().then(({ data }) => {
      this.popularCategory = data.data;
    });
    
    // this.getBrandList().then(({ data }) => (this.brandList = data.data.docs));

    this.getBrandAll().then(({ data }) => (this.brandList = _.sortBy(data,"name")));
    this.getPopularBrands().then(({ data }) => (this.popularBrands = data.data));

    window.addEventListener('resize', this.getFeaturedWidth, false);
    document.addEventListener('DOMContentLoaded', this.getFeaturedWidth, false);
    document.addEventListener('mousemove', this.getFeaturedWidth, false);
    window.addEventListener('load', this.getFeaturedWidth);

    window.addEventListener('resize', this.getCategoriesWidth, false);
    document.addEventListener('DOMContentLoaded', this.getCategoriesWidth, false);
    document.addEventListener('mousemove', this.getCategoriesWidth, false);
    window.addEventListener('load', this.getCategoriesWidth);
  },  
};
</script>

<style scoped lang="scss">
.mega-menu-fade-enter-active,
.mega-menu-fade-leave-active {
  transition: all 0.1s ease-in-out;
}

.menu-container {
  @media (min-width: 1200px) {
    width: calc(100vw - 297px - var(--scrollbar-width));
  }
  @media (max-width: 1200px) {
    width: calc(100vw - 36px - var(--scrollbar-width));
  }
  
}

.category-items {
  min-width: 190px;
  line-height: 2rem;
  margin-right: 20px;
  cursor: pointer;
  writing-mode: horizontal-tb;
}

.menu-featured-category-width {
  min-width: 190px;
  width: var(--megamenu-featured-category-width) !important;
}

.menu-featured-brands-width {
  min-width: 190px;
  width: var(--megamenu-featured-brands-width) !important;
}

.menu-categories-width {
  min-width: 190px;
  width: var(--megamenu-categories-width) !important;
}

.menu-height{
  min-width: 190px;
  writing-mode: vertical-lr;
  max-height: auto;
  @media (min-width: 768px) {
    height: 570px;
  }
  @media (min-width: 900px) {
    height: 550px;
  }
  @media (min-width: 1400px) {
    height: 510px;
  }
}

/* a.category.parent {} */
</style>
