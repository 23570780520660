<template>
  <ul>
    <li v-for="tree in treeCategories" :key="tree._id" ><vs-checkbox  v-model="options" :vs-value="tree._id" @change="getOptions(options)"><span style="font-size: 10px;">{{ tree.name}}</span>
    </vs-checkbox>
     <TreeItem v-if="tree.ancestors" :tree-categories="tree.ancestors" :categoriesOptions="options" @parentOptions="allOptionData"></TreeItem>

    </li>
  </ul>


<!--  <ul>-->
<!--    <li>  <vs-checkbox  v-model="option">-->
<!--      Milk Tea-->
<!--    </vs-checkbox></li>-->
<!--    <li><vs-checkbox  v-model="option">Tea-->
<!--      &lt;!&ndash; nested list &ndash;&gt;-->
<!--    </vs-checkbox><ul>-->
<!--      <li><vs-checkbox  v-model="option">-->
<!--        Black tea</vs-checkbox></li>-->
<!--      <li><vs-checkbox  v-model="option">-->
<!--        Green tea</vs-checkbox>-->
<!--        <ul>-->
<!--          <li><vs-checkbox  v-model="option">Black tea</vs-checkbox></li>-->
<!--          <li><vs-checkbox  v-model="option">Green tea</vs-checkbox></li>-->
<!--        </ul></li>-->
<!--    </ul>-->
<!--    </li>-->
<!--  </ul>-->
</template>

<script>
    import {mapState,mapActions} from 'vuex';
    export default {
        name: "TreeItem",
      props:['treeCategories','categoriesOptions'],
      data() {
          return {
            // options:[]

          }
      },
      created() {
      },
      methods:{
        // ..mapActions('ecommerce',['setCategoryData']),
          getOptions(value){
            this.$emit('parentOptions',value)
          },
        allOptionData(optionsData){
          this.$emit('parentOptions',optionsData)
        }
      },
      computed:{
          ...mapState('ecommerce',['categoryOptions']),
         options:{
            get()
           {
             return this.categoryOptions;
           },
           set(value){
             this.$store.dispatch('ecommerce/setCategoryData',value);
           //   // console.log(value);
           //   this.$store.state.categoriesOptions = value;
           //   // this.$store.commit("TOGGLE_ITEM_IN_WISH_LIST", value);
           }
        }
      }
    }

</script>

<style scoped>

</style>
